import React, { useState, forwardRef, useImperativeHandle } from 'react';
export const Alert = (props, ref) => {
    const message = props.message;
    const [open, setOpen] = useState('');
    const modaOpen = () => {
        setOpen('open');
    }
    const modaClose = () => {
        setOpen('');
    }
    useImperativeHandle(ref, () => ({ modaOpen }));
    return (
        <div className={`overflow ${open}`}>
            <div className='modal-content'>
                <div className='modal-header flex-end'>
                    <span className='close' onClick={() => { modaClose() }}>&times;</span>
                </div>
                <div className='modal-body'>{message}</div>
            </div>
        </div>
    )
}
export default forwardRef(Alert);