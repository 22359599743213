import React, { useState, useEffect, forwardRef, useImperativeHandle } from 'react';
import Config from '../Config/Config';
import { Link } from 'react-router-dom';
export const ModalNoSchet = (props, ref) => {
    const [open, setOpen] = useState('');
    useImperativeHandle(ref, () => ({ modaOpen }));
    let config = new Config();
    const modaOpen = () => {
        setOpen('open');


    }

    const modaClose = () => {
        setOpen('');
    }
    return (
        <div className={`overflow ${open}`} onTouchMove={() => modaClose()} >
            <div className='modal-container-bottom'>
                <div className='header-modal'>
                    <div className='close-modal' onClick={() => { modaClose() }}></div>
                </div>
                <div className='body-modal'>
                    <div className="title-modal">Добавьте свой лицевой счет</div>
                    <div className="desc-modal">Для того, чтобы вы могли создавать заявки и оплачивать платежи</div>
                    <div className="link-container"><Link className="btn btn-orange" to="/profil">Добавить лицевой счет</Link></div>
                </div>
            </div>
        </div >
    )
}
export default forwardRef(ModalNoSchet);