import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
export const Menu = (props) => {
    const link = props.link;
    return (
        <div className="fixed-menu">
            <div className="flex-betwen flex-wrap menu-wrap">
                <Link className={`home-link ${link == '/' ? 'active' : ''}`} to="/">
                    Главная
                </Link>
                <Link className={`order-link ${link == '/orders' ? 'active' : ''}`} to="/orders">
                    Заявки
                </Link>
                <Link className={`pay-link ${link == '/pay' ? 'active' : ''}`} to="/pay">
                    Платежи
                </Link>
                <Link className={`market-link ${link == '/market' ? 'active' : ''}`} to="/market">
                    Маркетплейс
                </Link>
                <Link className={`profil-link ${link == '/profil' ? 'active' : ''}`} to="/profil">
                    Профиль
                </Link>
            </div>
        </div>
    )
}
export default Menu;
