import React, { useState, useEffect, useRef } from 'react';

import Header from '../Elements/Header';
import { Link } from 'react-router-dom';
import Get from '../Config/Get';
import Menu from '../Elements/Menu';
import Store from '../Store/Store';
import Config from '../Config/Config';
import { jwtDecode } from "jwt-decode";
import ModalAddSchet from '../Elements/ModalAddSchet';
import ModalInfo from '../Elements/ModalInfo';
import ModalDeleteLs from '../Elements/ModalDeleteLs';
export const Profil = () => {
    const modal = useRef(null);
    const modalinf = useRef(null);
    const modalLs = useRef(null);
    let config = new Config();
    const [names, setName] = useState({
        name: '',
        last_name: ''
    })
    const [shects, setShets] = useState([]);
    const [user, setUser] = useState({});

    const getSchets = async (id) => {
        let data = [];

        if (Store.schets.time < Date.now()) {
            data = await Get.get('api/personal-accounts');

            if (data.status !== 404) {
                for (let i = 0; i < data.data.length; i++) {
                    if (data.data[i].account == '0000000000000000') {
                        data.data.splice(i, 1);
                    }
                }
                if (Array.isArray(data.data)) {
                    Store.schets.data = data.data;
                    Store.schets.time = Date.now() * 60 * 60 * 24 * 7;
                } else {
                    Store.schets.data = [];
                }
            }
        }
        setShets(Store.schets.data);
    }
    const DeleteSchet = async (id) => {

        let data = await Get.delete('api/personal-accounts/' + id);
        if (data.status == 204) {
            updateSchets();
        }
    }
    const getUser = async () => {
        let tokenData = JSON.parse(localStorage.getItem('token'));
        let tokenDataDecode = {};
        if (typeof tokenData == 'object') {
            tokenDataDecode = jwtDecode(tokenData.token);

        }
        if (Store.user.time < Date.now()) {
            let data = await Get.get('api/users/' + tokenDataDecode.user_id);
            Store.user.data = data;
            Store.user.time = Date.now() * 60 * 60 * 24 * 365;


        }
        return Store.user.data;


    }
    const getRandomColor = () => {
        var letters = '0123456789ABCDEF';
        var color = '#';
        for (var i = 0; i < 6; i++) {
            color += letters[Math.floor(Math.random() * 16)];
        }
        return color;
    }
    const updateSchets = () => {
        Store.schets.time = 0;
        getSchets();
    }
    const modalOpen = () => {
        let data = { func: updateSchets }
        if (modal.current !== null) {
            modal.current.modaOpen(data);
        }


    }
    const modalInfoOpen = () => {

        if (modal.current !== null) {
            modalinf.current.modaOpen({
                title: 'Выход из аккаунта',
                content: 'Вы действительно хотите выйти из аккаунта?',
                func: exitAcc
            });
        }


    }
    const modalLSOpen = (data) => {

        if (modalLs.current !== null) {
            modalLs.current.modaOpen(data);
        }


    }
    const modalInfoOpen2 = () => {

        if (modalinf.current !== null) {
            modalinf.current.modaOpen({
                title: 'Удаление аккаунта',
                content: 'Вы действительно хотите удалить аккаунт? Удаление ваших данных произойдет в течение 10 минут',
                func: exitAcc
            });
        }


    }
    const exitAcc = () => {
        localStorage.clear();
        window.location.href = '/login';
    }
    useEffect(() => {
        const as = async () => {
            let userdata = await getUser();

            let tokenData = JSON.parse(localStorage.getItem('token'));
            let tokenDataDecode = {};
            if (typeof tokenData == 'object') {
                tokenDataDecode = jwtDecode(tokenData.token);
                let user = {
                    color: getRandomColor(),
                    short: tokenDataDecode.name[0] + tokenDataDecode.name[1],
                    name: tokenDataDecode.name,
                    phone: userdata.phoneNumber,
                    email: tokenDataDecode.email,
                    user_id: tokenDataDecode.user_id
                };

                if (!localStorage.getItem('user')) {
                    localStorage.setItem('user', JSON.stringify(user));
                } else {
                    user = JSON.parse(localStorage.getItem('user'));
                }
                setUser(user);

                getSchets(user.user_id);
            }

        }
        as();


    }, [])
    return (
        <>
            <div className='profil-page menu-container'>
                <Header data={{ title: 'Профиль' }} nazad={true} />
                <div className='container pt-40'>
                    <div className='flex-betwen flex-wrap profil-wrap'>
                        <div className='prev-container'>
                            <div className='radius' style={{ background: user.color }}>
                                {user.short}
                            </div>
                        </div>
                        <div className='info-container grow'>
                            <div className='name title'>{user.name}</div>
                            <div className='pt-15'></div>

                            <div className='phone grey-text'>{user.phone !== undefined ? config.formatNumber(user.phone) : ''}</div>
                            <div className='pt-15'></div>
                            <div className='email grey-text'>{user.email}</div>
                            <div className='pt-15'></div>
                            <div className='link-wrap'>
                                <Link to='/editprofil'>Редактировать</Link>
                            </div>

                        </div>
                    </div>
                    <div className='pt-40'></div>


                    <div className='pt-30'></div>
                    <div className='link-block'>
                        <a onClick={() => { modalInfoOpen() }} className='logout'>Выйти из аккаунта</a>
                    </div>
                    <div className='pt-30'></div>
                    <div className='input-block'>
                        <button type='button' className='btn btn-red' onClick={() => { modalInfoOpen2() }}>Удалить аккаунт</button>
                    </div>
                </div>


            </div>
            <ModalAddSchet ref={modal} />
            <ModalInfo ref={modalinf} />
            <ModalDeleteLs ref={modalLs} />
        </>
    )
}
export default Profil;
