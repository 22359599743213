import React, { useState, useEffect } from 'react';

import Header from '../Elements/Header';
import { Link } from 'react-router-dom';
import Menu from '../Elements/Menu';
export const Pay = () => {





    useEffect(() => {


    }, [])
    return (
        <div className='home-page menu-container'>
            <Header data={{ title: 'Платежи' }} nazad={true} />
            <div className='container develop-page'>
                <div className='title-page'>Страница в разработке!</div>
            </div>

            <Menu link='/pay' />
        </div>
    )
}
export default Pay;