import React, { useState, useEffect } from 'react';

import Header from '../Elements/Header';
import { Link } from 'react-router-dom';
import Get from '../Config/Get';
import Menu from '../Elements/Menu';
import Store from '../Store/Store';
import Config from '../Config/Config';
import { jwtDecode } from "jwt-decode";
import InputPass from '../Elements/InputPass';
import Validate from '../Config/Validate';
export const Changepass = () => {
    let config = new Config();

    const [form, setForm] = useState(
        {
            value: {
                password: '',
                repassword: ''
            },

            error: {
                password: '',
                repassword: ''
            },
            focus: {
                password: '',
                repassword: ''
            }
        }
    );
    const setValue = (name, value) => {

        setForm({
            ...form,
            value: {
                ...form.value,
                [name]: value
            }, focus: {
                ...form.focus,
                [name]: value !== '' ? 'focus' : ''
            }, error: {
                ...form.error,
                [name]: ''
            }
        })
    }
    const sendForm = async (e) => {
        e.preventDefault();

        if (Validate.Empty(form.value.password)) {
            setForm({
                ...form,
                error: {
                    ...form.error,
                    password: 'Поле обязательно для заполнения'
                },
                focus: {
                    ...form.focus,
                    password: 'focus error'
                }
            });
            return;
        }
        if (Validate.Empty(form.value.repassword)) {
            setForm({
                ...form,
                error: {
                    ...form.error,
                    repassword: 'Поле обязательно для заполнения'
                },
                focus: {
                    ...form.focus,
                    repassword: 'focus error'
                }
            });
            return;
        }
        if (Validate.Count(form.value.password, 5, 12)) {
            setForm({
                ...form,
                error: {
                    ...form.error,
                    password: 'Поле должно содержать не менее 5 и не более 12 символов'
                },
                focus: {
                    ...form.focus,
                    password: 'focus error'
                }
            });
            return;
        }

        setForm({
            ...form,

            focus: {
                password: 'loaded',
                repassword: 'loaded'
            }
        }
        );
        let tokenData = JSON.parse(localStorage.getItem('token'));

        let tokenDataDecode = {};
        if (typeof tokenData == 'object') {
            tokenDataDecode = jwtDecode(tokenData.token);

        }
        tokenDataDecode = jwtDecode(tokenData.token);
        // console.log(tokenDataDecode.user_id);

        let res = await Get.patch({
            oldPassword: form.value.password,
            newPassword: form.value.repassword
        }, 'api/account/change-password', false);

        if (res.status == 409 && res.hasOwnProperty('detail') && res.detail == 'Input incorrect old password') {
            setForm({
                ...form,
                error: {
                    password: 'Старый пароль введен неверно',
                    repassword: ''
                },
                focus: {
                    password: 'focus error',
                    repassword: 'focus'
                }
            });
            return;
        }
        setForm({
            ...form,

            focus: {
                password: 'focus',
                repassword: 'focus'
            }
        }
        );
    }


    useEffect(() => {
        const as = async () => {


        }
        as();


    }, [])
    return (
        <div className='profil-page menu-container'>
            <Header data={{ title: 'Смена пароля' }} nazad={true} />
            <div className='container pt-20'>
                <form onSubmit={(e) => { sendForm(e) }}>
                    <div className='title-body pb-40'>Введите новый пароль</div>
                    <InputPass value={form.value.password} label="Старый пароль" id="Password" onChange={(e) => { setValue('password', e.target.value) }} focus={form.focus.password} error={form.error.password} />
                    <InputPass value={form.value.repassword} label="Новый пароль" id="Repassword" onChange={(e) => { setValue('repassword', e.target.value) }} focus={form.focus.repassword} error={form.error.repassword} />
                    <div className='input-block pt-20'>
                        <button type='submit' className='btn btn-orange'>Сохранить</button>
                    </div>
                </form>
            </div>

            <Menu link='/profil' />

        </div>
    )
}
export default Changepass;