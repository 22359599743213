// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `[class*=flex-] {
    display: flex;
}
.flex-center {
    justify-content: center;
}
.flex-start {
    justify-content: flex-start;
}
.flex-end {
    justify-content: flex-end;
}
.flex-betwen {
    justify-content: space-between;
}
.flex-around {
    justify-content: space-around;
}
.flex-top {
    align-items: flex-start;
}
.flex-bottom {
    align-items: flex-end;
}
.flex-baseline {
    align-items: baseline;
}
.flex-vertical-center {
    align-items: center;
}
.flex-srethc {
    align-items: stretch;
}
.flex-wrap {
    flex-wrap: wrap;
}
.flex-collumn{
    flex-direction: column;
}
.flex-collumn>*{
    width: 100%;
    display: block;
}
.grow {
    flex-grow: 1;
}
`, "",{"version":3,"sources":["webpack://./src/Css/flex.setting.css"],"names":[],"mappings":"AAAA;IAGI,aAAa;AACjB;AACA;IAGI,uBAAuB;AAC3B;AACA;IAGI,2BAA2B;AAC/B;AACA;IAGI,yBAAyB;AAC7B;AACA;IAGI,8BAA8B;AAClC;AACA;IAEI,6BAA6B;AACjC;AACA;IAGI,uBAAuB;AAC3B;AACA;IAGI,qBAAqB;AACzB;AACA;IAGI,qBAAqB;AACzB;AACA;IAGI,mBAAmB;AACvB;AACA;IAGI,oBAAoB;AACxB;AACA;IAEI,eAAe;AACnB;AACA;IACI,sBAAsB;AAC1B;AACA;IACI,WAAW;IACX,cAAc;AAClB;AACA;IAGI,YAAY;AAChB","sourcesContent":["[class*=flex-] {\n    display: -webkit-box;\n    display: -ms-flexbox;\n    display: flex;\n}\n.flex-center {\n    -webkit-box-pack: center;\n    -ms-flex-pack: center;\n    justify-content: center;\n}\n.flex-start {\n    -webkit-box-pack: start;\n    -ms-flex-pack: start;\n    justify-content: flex-start;\n}\n.flex-end {\n    -webkit-box-pack: end;\n    -ms-flex-pack: end;\n    justify-content: flex-end;\n}\n.flex-betwen {\n    -webkit-box-pack: justify;\n    -ms-flex-pack: justify;\n    justify-content: space-between;\n}\n.flex-around {\n    -ms-flex-pack: distribute;\n    justify-content: space-around;\n}\n.flex-top {\n    -webkit-box-align: start;\n    -ms-flex-align: start;\n    align-items: flex-start;\n}\n.flex-bottom {\n    -webkit-box-align: end;\n    -ms-flex-align: end;\n    align-items: flex-end;\n}\n.flex-baseline {\n    -webkit-box-align: baseline;\n    -ms-flex-align: baseline;\n    align-items: baseline;\n}\n.flex-vertical-center {\n    -webkit-box-align: center;\n    -ms-flex-align: center;\n    align-items: center;\n}\n.flex-srethc {\n    -webkit-box-align: stretch;\n    -ms-flex-align: stretch;\n    align-items: stretch;\n}\n.flex-wrap {\n    -ms-flex-wrap: wrap;\n    flex-wrap: wrap;\n}\n.flex-collumn{\n    flex-direction: column;\n}\n.flex-collumn>*{\n    width: 100%;\n    display: block;\n}\n.grow {\n    -webkit-box-flex: 1;\n    -ms-flex-positive: 1;\n    flex-grow: 1;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
