import { Outlet, Navigate, useLocation } from 'react-router-dom';
import React, { useState, useEffect } from 'react';
import Config from '../Config/Config';
import Get from '../Config/Get';
export const PrivateRoute = () => {
  var Autch = localStorage.getItem("token");
  const location = useLocation();

  useEffect(() => {
    let tokendata = JSON.parse(Autch);
    if (tokendata && tokendata.hasOwnProperty('refreshTokenExpiryTime') && tokendata.refreshTokenExpiryTime !== null) {
      let datetoken = Math.floor(new Date(tokendata.refreshTokenExpiryTime).getTime());
      let datethis = Date.now();
      if (datetoken <= datethis) {
        Get.refreshToken();
      }
    } else {
      localStorage.clear();
      Autch = null;

    }
  }, []);
  // localStorage.clear();
  return Autch !== null ? (
    <div className="outlet-container">
      <Outlet />
      {/* <div id="SocketLoader" className="socket-loader">Подключение</div> */}
    </div>
  ) : (
    <Navigate to="/login" state={{ from: location }} replace />
  );
};
export default PrivateRoute;
