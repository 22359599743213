import React, { useState, useEffect, useRef } from 'react';
import Header from '../Elements/Header';
import { Link } from 'react-router-dom';
import InputPass from '../Elements/InputPass';
import InputMask from 'react-input-mask';
import Validate from '../Config/Validate';
import Get from '../Config/Get';
import Alert from '../Elements/Alert';
import { v4 as uuidv4 } from 'uuid';
export const Login = () => {
    const modal = useRef(null);
    const [form, setForm] = useState(
        {
            value: {
                email: '',
                password: ''
            },

            error: {
                email: '',
                password: ''
            },
            focus: {
                email: '',
                password: ''
            }
        }
    );
    const setValue = (name, value) => {

        setForm({
            ...form,
            value: {
                ...form.value,
                [name]: value
            }, focus: {
                ...form.focus,
                [name]: value !== '' ? 'focus' : ''
            }, error: {
                ...form.error,
                [name]: ''
            }
        })
    }
    const sendForm = async (e) => {
        e.preventDefault();
        if (Validate.Empty(form.value.email)) {
            setForm({
                ...form,
                error: {
                    ...form.error,
                    email: 'Поле обязательно для заполнения'
                },
                focus: {
                    ...form.focus,
                    email: 'focus error'
                }
            });
            return;
        }
        if (Validate.Count(form.value.email, 5, 26)) {
            setForm({
                ...form,
                error: {
                    ...form.error,
                    email: 'Не корректный адрес электронной почты'
                },
                focus: {
                    ...form.focus,
                    email: 'focus error'
                }
            });
            return;
        }
        if (Validate.Empty(form.value.password)) {
            setForm({
                ...form,
                error: {
                    ...form.error,
                    password: 'Поле обязательно для заполнения'
                },
                focus: {
                    ...form.focus,
                    password: 'focus error'
                }
            });
            return;
        }
        if (Validate.Count(form.value.password, 5, 26)) {
            setForm({
                ...form,
                error: {
                    ...form.error,
                    password: 'Поле должно содержать не менее 6 и не более 12 символов'
                },
                focus: {
                    ...form.focus,
                    password: 'focus error'
                }
            });
            return;
        }

        let uuid = uuidv4();
        let data = await Get.post({
            email: form.value.email,
            password: form.value.password,
        }, 'api/identity/token');


        if (data.status == 409) {
            setForm({
                ...form,
                error: {
                    ...form.error,
                    password: 'Неправильный логин или пароль'
                },
                focus: {
                    ...form.focus,
                    password: 'focus error'
                }
            });
            return;
        }
        if (data.status == 404) {
            setForm({
                ...form,
                error: {
                    ...form.error,
                    phone: 'Такого пользователя нет в системе'
                },
                focus: {
                    ...form.focus,
                    phone: 'focus error'
                }
            });
            return;
        }
        if (data.status !== undefined && data.status !== 200) {
            if (modal.current !== null) {
                modal.current.modaOpen();
            }
            return
        }

        localStorage.setItem('token', JSON.stringify(data));
        localStorage.setItem('UserId', uuid);
        window.location.href = '/';
    }
    return (
        <>
            <div className='login-page'>
                <Header data={{ title: 'Вход' }} />
                <div className='pt-40'></div>
                <div className='container'>
                    <div className='login-form'>
                        <form onSubmit={(e) => { sendForm(e) }}>
                            <div className={`input-block ${form.focus.email}`}>
                                <InputMask
                                    id="Email"
                                    type="text"
                                    maskChar={null}
                                    value={form.value.phone}
                                    onChange={(e) => { setValue('email', e.target.value) }} />
                                <label htmlFor="Email">Email</label>
                                <span className='error'>{form.error.phone}</span>
                            </div>
                            <InputPass value={form.value.password} label="Пароль" onChange={(e) => { setValue('password', e.target.value) }} focus={form.focus.password} error={form.error.password} />
                            <div className='input-block'>
                                <button type='submit' className='btn btn-orange'>Войти</button>
                            </div>

                        </form>
                    </div>
                    <div className='pt-20'></div>

                </div>
            </div>
            <Alert message="Возникла ошибка соединения, попробуйте позже" ref={modal} />
        </>
    )
}
export default Login;
