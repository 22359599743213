const Config = class {
    constructor() {
        //
        //this.url = "//doma-pay.ru/";
        //  this.url = "//localhost:8010/proxy";
        // this.ws = "wss://doma-pay.store:3001/";
        // this.url = "http://31.129.110.147:5252";
        // this.ws = "http://31.129.110.147:5252/hub";
        this.url = "https://develop-api.doma-pay.ru";
        this.ws = "https://develop-api.doma-pay.ru/hub";
        //http://develop.doma-pay.ru:5252/
    }
    SchetFormat(num) {
        var n = num.toString();
        var separator = " ";
        return n.replace(/(\d{1,2}(?=(?:\d\d\d)+(?!\d)))/g, "$1" + separator);
    }
    formatNumber(phoneNo) {
        return phoneNo
            .replace(/(\d{1})(\d{3})(\d{3})(\d{4})/,
                '+$1($2)-$3-$4')

    }
    dateFormatTimestap(data) {


        // let date = new Date(Number(unix) * 1000);
        let unix = Math.floor(new Date(data).getTime() / 1000);
        let dateUnix = new Date(Number(unix) * 1000);
        let options = { year: 'numeric', month: 'numeric', day: 'numeric' };
        return dateUnix.toLocaleString('ru-RU', options);
    }
    dateFormatTimestapTime(data) {


        // let date = new Date(Number(unix) * 1000);
        let unix = Math.floor(new Date(data).getTime() / 1000);
        let dateUnix = new Date(Number(unix) * 1000);
        let options = { year: 'numeric', month: 'numeric', day: 'numeric' };
        let optionstime = { hour: 'numeric', minute: 'numeric' };
        return dateUnix.toLocaleString('ru-RU', options) + ' ' + dateUnix.toLocaleTimeString('ru-RU', optionstime);
    }
    dateFormat(unix) {


        let date = new Date(Number(unix) * 1000);
        let options = { year: 'numeric', month: 'numeric', day: 'numeric' };
        return date.toLocaleString('ru-RU', options);
    }
    dateTimeFormat(unix) {

        let date = new Date(Number(unix) * 1000);
        let options = { year: 'numeric', month: 'numeric', day: 'numeric' };
        let optionstime = { hour: 'numeric', minute: 'numeric' };
        return date.toLocaleString('ru-RU', options) + ' ' + date.toLocaleTimeString('ru-RU', optionstime);
    }
};

export default Config;