import React, { useState, useEffect, forwardRef, useImperativeHandle, useRef } from 'react';
import Get from '../Config/Get';
import Store from '../Store/Store';
import Config from '../Config/Config';
import { jwtDecode } from "jwt-decode";
export const ModalPay = (props, ref) => {
    const [open, setOpen] = useState('');
    const modal2 = useRef(null);
    useImperativeHandle(ref, () => ({ modaOpen }));

    const modaOpen = async (data) => {
        console.log(data);

        let res = await Get.get('api/payments/' + data.paymentId, false);

        if (res.hasOwnProperty('externalPaymentId')) {
            document.getElementById('payment-form').classList.add('loaded-block');
            document.getElementById('payment-form').innerHTML = '';
            let token = 'ct-' + res.externalPaymentId;
            createFormPay(token);
        }
        // createFormPay(data.paymentId);
        setOpen('open');
    }
    const modaClose = () => {
        setOpen('');
    }
    const createFormPay = (token) => {
        const script = document.createElement('script');
        script.src = 'https://yookassa.ru/checkout-widget/v1/checkout-widget.js';
        document.body.appendChild(script);
        script.onload = () => {
            document.getElementById('payment-form').classList.remove('loaded-block');
            const checkout = new window.YooMoneyCheckoutWidget({
                confirmation_token: token, //Токен, который перед проведением оплаты нужно получить от ЮKassa
                return_url: 'https://www.new.doma-pay.ru/services', //Ссылка на страницу завершения оплаты, это может быть любая ваша страница

                //При необходимости можно изменить цвета виджета, подробные настройки см. в документации
                customization: {
                    //Настройка цветовой схемы, минимум один параметр, значения цветов в HEX
                    colors: {
                        //Цвет акцентных элементов: кнопка Заплатить, выбранные переключатели, опции и текстовые поля
                        control_primary: '#FFB02E', //Значение цвета в HEX

                        //Цвет платежной формы и ее элементов
                        //background: '#F2F3F5' //Значение цвета в HEX
                    }
                },
                error_callback: function (error) {
                    console.log(error)
                }
            });

            //Отображение платежной формы в контейнере
            checkout.render('payment-form');


        };
    }
    useEffect(() => {

    }, [])
    return (
        <div style={{zIndex: 99999}}>
            <div className={`overflow ${open}`}>
                <div className='modal-order-container'>
                    <div className='header-modal flex-betwen flex-wrap'>
                        <div className='close-container'>
                            <div className='close-modal' onClick={() => { modaClose() }}></div>
                        </div>
                        <div className='title grow'>Оплата</div>
                    </div>
                    <div className='body-modal'>
                        <div className='container'>
                            <div className={'loaded-block'} id="payment-form"></div>
                        </div>
                    </div>
                </div>
            </div>

        </div>
    )
}
export default forwardRef(ModalPay);