import React, { useState, useEffect, forwardRef, useImperativeHandle } from 'react';
import Get from '../Config/Get';
import Store from '../Store/Store';
import InputMask from 'react-input-mask';
import Validate from '../Config/Validate';
import { jwtDecode } from "jwt-decode";
export const ModalAddSchet = (props, ref) => {
    const [open, setOpen] = useState('');
    useImperativeHandle(ref, () => ({ modaOpen }));
    const [uuid, setUuid] = useState('');
    const [propdata, setPropData] = useState(null);
    const [form, setForm] = useState({
        value: {
            schet: '',
            summ: ''
        },

        error: {
            schet: '',
            summ: ''
        },
        focus: {
            schet: '',
            summ: ''
        }
    });
    const modaOpen = (data) => {
        setOpen('open');
        setPropData(data);

    }
    const setCompany = async (schet) => {
        let tokenData = JSON.parse(localStorage.getItem('token'));


        let token = await Get.post({
            "personalAccountId": schet,
            "refreshToken": tokenData.refreshToken
        }, 'api/identity/token/refresh');
        localStorage.setItem('token', JSON.stringify(token));

    }
    const setValue = (name, value) => {

        setForm({
            ...form,
            value: {
                ...form.value,
                [name]: value
            }, focus: {
                ...form.focus,
                [name]: value !== '' ? 'focus' : ''
            }, error: {
                ...form.error,
                [name]: ''
            }
        })
    }
    const sendForm = async (e) => {
        e.preventDefault();

        if (Validate.Empty(form.value.schet)) {
            setForm({
                ...form,
                error: {
                    ...form.error,
                    schet: 'Поле обязательно для заполнения'
                },
                focus: {
                    ...form.focus,
                    schet: 'focus error'
                }
            });
            console.log(form);
            return;
        }
        if (Validate.Empty(form.value.summ)) {
            setForm({
                ...form,
                error: {
                    ...form.error,
                    summ: 'Поле обязательно для заполнения'
                },
                focus: {
                    ...form.focus,
                    summ: 'focus error'
                }
            });
            return;
        }
        let data = await Get.post({
            account: Validate.Phone(form.value.schet)
        }, 'api/personal-accounts');
        if (typeof data == 'object') {
            if (data.status > 201) {
                setForm({
                    ...form,
                    error: {
                        ...form.error,
                        schet: 'Такого счета не существует'
                    },
                    focus: {
                        ...form.focus,
                        schet: 'focus error'
                    }
                });
            } else {
                setCompany();
                if (propdata !== null && propdata.func !== undefined) {
                    propdata.func();
                    modaClose();
                }
            }
        }
    }
    const modaClose = () => {
        setOpen('');
    }
    useEffect(() => {
        const as = async () => {
            let tokenData = JSON.parse(localStorage.getItem('token'));
            let tokenDataDecode = {};
            if (typeof tokenData == 'object') {
                tokenDataDecode = jwtDecode(tokenData.token);
                setUuid(tokenDataDecode.user_id);

            }

        }
        as();


    }, [])
    return (
        <div className={`overflow ${open}`}>
            <div className='modal-container grey-input'>
                <div className='flex-betwen flex-wrap header-modal'>

                    <div className='title grow'>Введите лицевой счет</div>
                    <div className='close-container'>
                        <div className='close-modal' onClick={() => { modaClose() }}>&times;</div>
                    </div>
                </div>
                <div className='body-modal pt-20'>
                    <form onSubmit={(e) => { sendForm(e) }}>
                        <div className='pt-20'></div>
                        <div className={`input-block ${form.focus.schet}`}>
                            <InputMask id="Schet" type="text" maskChar={null} mask="99 99 99 99 99" value={form.value.schet} onChange={(e) => { setValue('schet', e.target.value) }} />

                            <span className='error'>{form.error.schet}</span>
                        </div>
                        <div className='pt-20'></div>
                        <div className='title-modal-desc modal-desc'>
                            Укажите сумму любого из двух последних платежей, включая копейки.
                        </div>
                        <div className='pt-20'></div>
                        <div className={`input-block ${form.focus.summ}`}>
                            <input type="text" value={form.value.summ} onChange={(e) => { setValue('summ', e.target.value) }} placeholder='Пример: 2242,22 руб' />

                            <span className='error'>{form.error.summ}</span>
                        </div>
                        <div className="input-block">
                            <button type='submit' className='btn btn-orange'>Добавить</button>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    )
}
export default forwardRef(ModalAddSchet);