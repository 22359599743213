import React, { useState, useEffect, useCallback, useRef } from 'react';

import Header from '../Elements/Header';
import { Link, Navigate } from 'react-router-dom';
import Get from '../Config/Get';
import ModalInfo from '../Elements/ModalInfo';
import Store from '../Store/Store';
import Config from '../Config/Config';
import { jwtDecode } from "jwt-decode";
import ImageViewer from 'react-simple-image-viewer';
import ModalPay from '../Elements/ModalPay';

export const Service = () => {
    const [order, setOrder] = useState(null);
    const [currentImage, setCurrentImage] = useState(0);
    const [isViewerOpen, setIsViewerOpen] = useState(false);
    const [images, setImages] = useState([]);
    const [nav, SetNav] = useState(0);
    const modalP = useRef(null);

    let config = new Config();

    const modal = useRef(null);
    const getCompleteStatus = async () => {
        let res = await Get.get('api/statuses?type=Paid');
        if (res.hasOwnProperty('data') && Array.isArray(res.data)) {
            let completeStatus = res.data.filter(item => item.name === 'Выполнено')[0];
            return completeStatus.statusId;
        }
        return false;
    }
    const OrderComplete = async (order) => {
        console.log(order)
        let data = await Get.put(null,  `api/paid-requests/${order.paidRequestId}/complete`);

        if (data.type == 'success') {
            Store.services.time = 0;
            SetNav(1);
        }
    }


    const deleteOrder = async () => {
        Store.services.time = 0;
        await Get.delete('api/paid-requests/' + order.paidRequestId)
        SetNav(1);
    }
    const deleteOrderModal = () => {
        if (modal.current !== null) {
            modal.current.modaOpen({
                title: 'Удаление заявки',
                content: 'Вы действительно хотите отменить заявку №' + order.paidRequestNumber,
                func: deleteOrder

            });
        }
    }
    const getOrders = async () => {

        if (Store.services.time < Date.now()) {
            let data = await Get.get('api/paid-requests');

            Store.services.data = data.data;
            Store.services.time = Date.now() * 60 * 60;
        }

        return Store.services.data;
    }
    const getAppealType = async () => {
        if (Store.appealtypespaid.time < Date.now()) {
            let data = await Get.get('api/organization-services?offset=0&count=100');
            Store.appealtypespaid.data = data.data;
            Store.appealtypespaid.time = Date.now() * 60 * 60 * 24;

        }
        return Store.appealtypespaid.data;
    }
    const setNameAppeal = (organizationServiceId, appealtypespaid = []) => {
        let serciceName = ''
        appealtypespaid.map(
            (item) => {
                if (item.organizationServiceId == organizationServiceId) {
                    serciceName = item.name;
                }
            }
        );
        return serciceName;
    }
    const getOrder = async (id) => {
        let orders = await getOrders();
        var urlParams = new URLSearchParams(window.location.search);
        let order_id = urlParams.get('id');

        let order = orders.find(el => el.paidRequestId == order_id);
        if (!order.hasOwnProperty('icon')) {
            order = await getOrderetch(order_id, order);
        }
        let eppealtypes = await getAppealType();
        let servicesNames = [];
        for (let i = 0; i < order.organizationServices.length; i++) {
            let servicesName = setNameAppeal(order.organizationServices[i].organizationServiceId, eppealtypes);
            servicesNames.push(servicesName);
            order.organizationServices[i].serviceName = servicesName;
        }
        order.serciceName = servicesNames;
        setOrder(order);
    }
    const getOrderetch = async (id, order) => {
        let data = await Get.get('api/paid-requests/' + id);

        if (typeof data.status == 'object') {

            Store.services.data.map((el, index) => {
                if (el.requestId == data.paidRequestId) {
                    Store.services.data[index] = data;
                }
            })
            setImages(data.icons);
        } else {

            return order;
        }
        return data;
    }
    const openImageViewer = useCallback((index) => {
        setCurrentImage(index);
        setIsViewerOpen(true);
    }, []);

    const closeImageViewer = () => {
        setCurrentImage(0);
        setIsViewerOpen(false);
    };
    useEffect(() => {

        const as = async () => {
            getOrder();
        }
        as();
    }, []);
    if (order == null) {
        return
    }

    const createPay = async (order, e) => {
        if (!order.hasOwnProperty('paymentId')) {
            e.target.classList.add('loaded')
            let res = await Get.put({}, 'api/paid-requests/' + order.paidRequestId + '/start-payment', false);
            Store.services.time = 0;
            getOrders();
        } else {
            payOpen({ paymentId: order.paymentId })
        }

    }
    const payOpen = (data) => {
        var elems = document.querySelectorAll(".btn");

        [].forEach.call(elems, function (el) {
            el.classList.remove("loaded");
        });
        modalP.current.modaOpen(data);
    }
    const counterPrice = (organizationServices) => {
        let ptice = 0
        if (Array.isArray(organizationServices)) {
            organizationServices.forEach(item => {
                ptice += item.price
            })
        }
        return ptice
    }

    return (
        <>
            {nav == 1 ? <Navigate to='/services' /> :
                <div className='orders-page menu-container'>
                    <Header data={{ title: 'Услуга № ' + order.paidRequestNumber }} nazad={true} />
                    {console.log(order)}
                    <div className='container'>
                        <div className='pt-20'></div>
                        <div className='status-block title-status'>Статус: <span className='status-value'
                                                                                 style={{color: order.status.color}}>{order.status.name}</span>
                        </div>
                        <div className='pt-20'></div>
                        <div className='hr'></div>
                        <div className='pt-20'></div>
                        <div className='date-block'>{config.dateFormatTimestapTime(order.createdOn)}</div>
                        <div className='pt-20'></div>
                        <div className='value-label'>{order.serciceName.join(', ')}</div>
                        <div className='pt-20'></div>
                        <div className='app-category title-category'>{order.appealCategory.name}</div>
                        <div className='pt-10'></div>
                        <div className='message-block'>{order.description}</div>
                        <div className='pt-20'></div>

                        {order.hasOwnProperty('icons') && order.icons.length > 0 ?

                            <div className='images-block'>

                                <div className='pt-20'></div>
                                <div className='title-label'>Изображения</div>
                                <div className='pt-15'></div>
                                <div className='prevyou-wrap-images flex-start flex-wrap'>
                                    {order.icons.map((el, index) => {
                                        return (
                                            <div className='prevyou-image' key={index}>
                                                <img src={el} onClick={() => openImageViewer(index)}/>
                                            </div>
                                        )
                                    })}
                                </div>

                                <div className='pt-20'></div>
                            </div>

                            : ''}

                        <div className='hr'></div>
                        <div className='pt-20'></div>
                        <div className='title-label'>Контактное лицо</div>
                        <div className='pt-15'></div>
                        <div className='value-label'>{order.contactPerson}</div>
                        <div className='pt-20'></div>
                        <div className='hr'></div>
                        <div className='pt-20'></div>
                        <div className='title-label'>Телефон для связи</div>
                        <div className='pt-15'></div>
                        <div className='value-label orange'>{config.formatNumber(order.phoneNumber)}</div>
                        <div className='pt-20'></div>
                        <div className='hr'></div>
                        <div className='pt-20'></div>
                        <div className='title-label'>Адрес</div>
                        <div className='pt-15'></div>
                        <div className='value-label'>{order.personalAccount.address}</div>
                        <div className='pt-20'></div>
                        <div className='hr'></div>
                        <div className='pt-20'></div>
                        <div className='title-label'>Лицевой счет</div>
                        <div className='pt-15'></div>
                        <div className='value-label orange'>{config.SchetFormat(order.personalAccount.account)}</div>
                        <div className='pt-40'></div>


                        <div className='input-block'>
                            {order.hasOwnProperty('executorChatId') ?
                                <Link className='btn btn-white'
                                      to={`/chat?id=${order.executorChatId}&paidorder=${order.paidRequestNumber}`}>Чат с
                                    ответственным по услуге</Link>
                                : ''}

                        </div>

                        <div className='input-block'>
                            {order.hasOwnProperty('applicantChatId') ?
                                <Link className='btn btn-orange'
                                      to={`/chat?id=${order.applicantChatId}&paidorder=${order.paidRequestNumber}`}>Чат
                                    с клиентом</Link>
                                : ''}

                        </div>

                        <div className='input-block'>
                            {
                                (order.status.name !== 'Выполнена исполнителем' && order.status.name !== 'Выполнено')
                                    ? <button type='buttom' className='btn btn-green' onClick={() => {
                                        OrderComplete(order)
                                    }}>Подтвердить выполнение</button>
                                    : null
                            }
                        </div>

                        <div className='pt-20'></div>
                        <div className='pt-20'></div>
                    </div>
                </div>
            }
            {isViewerOpen && (
                <ImageViewer
                    src={images}
                    currentIndex={currentImage}
                    disableScroll={false}
                    closeOnClickOutside={true}
                    onClose={closeImageViewer}
                />
            )}
            <ModalInfo ref={modal}/>
            <ModalPay ref={modalP}/>
        </>
    )
}
export default Service;
