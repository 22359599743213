import Config from './Config';
import * as signalR from "@microsoft/signalr";
let config = new Config();

class Socket {
    connection;
    events;
    static instance;
    constructor() {
        let tokenData = JSON.parse(localStorage.getItem('token'));

        this.connection = new signalR.HubConnectionBuilder().withUrl(config.ws, {
            accessTokenFactory: () => tokenData.token,
            // skipNegotiation: true,
            // transport: signalR.HttpTransportType.WebSockets
        }).withAutomaticReconnect().build()


        this.events = (RequestChanged, AddMessage, PaidRequestChanged, PaymentCreated) => {
            this.connection.on("NewMessage", (message) => {
                // newMessageReceive(message)
                console.log("NEW MESSAGE")
            });

            this.connection.on('RequestChanged', () => {
                RequestChanged()
            })
            this.connection.on("AddMessage", (chatId) => {

                AddMessage(chatId.chatId)

            })
            this.connection.on("PaymentCreated", (data) => {
                console.log('payment')
                PaymentCreated(data)

            })

            this.connection.on("PaidRequestChanged", () => {
                PaidRequestChanged();

            })

        }

    }
    connectUser = () => {
        if (this.connection.state == signalR.HubConnectionState.Connected) {
            this.connection.send("ConnectNewUser").then(x => console.log("connected"))
        } else {
            try {
                this.connection.start().catch(err => { }).then(x =>
                    this.connection.send("ConnectNewUser").then(x => console.log("connected"))
                );
            } catch (e) { }
        }
    }
    reConnect = () => {

        let tokenData = JSON.parse(localStorage.getItem('token'));

        this.connection = new signalR.HubConnectionBuilder().withUrl(config.ws, {
            accessTokenFactory: () => tokenData.token,
            // skipNegotiation: true,
            // transport: signalR.HttpTransportType.WebSockets
        }).withAutomaticReconnect().build();
        this.connectUser();
    }
    static getInstance() {
        if (!Socket.instance) {
            Socket.instance = new Socket();
        }
        return Socket.instance;
    }

}
export default Socket.getInstance;