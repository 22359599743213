import React, { useState, useEffect, useRef } from 'react';

import Header from '../Elements/Header';
import { Link } from 'react-router-dom';
import Get from '../Config/Get';
import Menu from '../Elements/Menu';
import Store from '../Store/Store';
import Config from '../Config/Config';
import { jwtDecode } from "jwt-decode";
import ModalAddOrder1 from '../Elements/ModalAddOrderService1';
import ModalNoSchet from '../Elements/ModalNoSchet';
import ModalPay from '../Elements/ModalPay';
export const Services = () => {
    let config = new Config();
    const modalP = useRef(null);

    let tokenData = JSON.parse(localStorage.getItem('token'));
    let tokenDataDecode = {};
    if (typeof tokenData == 'object') {
        tokenDataDecode = jwtDecode(tokenData.token);
    }
    const [status, setStatus] = useState('active');

    const [orders, setOrders] = useState([])
    const getOrders = async () => {

        if (Store.services.time < Date.now()) {
            let tokenData = JSON.parse(localStorage.getItem('token'));
            tokenDataDecode = jwtDecode(tokenData.token);
            // console.log(tokenDataDecode.user_id);
            let data = await Get.get('api/executor/paid-requests');
            Store.services.data = data.data;
            Store.services.time = Date.now() * 60 * 60;

        }
        setOrders(Store.services.data);
    }
    const setCompany = async (schet) => {
        let tokenData = JSON.parse(localStorage.getItem('token'));


        let token = await Get.post({
            "personalAccountId": schet,
            "refreshToken": tokenData.refreshToken
        }, 'api/identity/token/refresh');
        localStorage.setItem('token', JSON.stringify(token));

        Store.services.time = 0;
        getOrders();
        ConnectSocket();
    }
    const ConnectSocket = async () => {
        const { connectUser, events, reConnect } = Get.socket();
        reConnect();


        const PaidRequestChanged = () => {

            Store.services.time = 0;
            getOrders();

        };
        const PaymentCreated = (data) => {
            payOpen(data);
        }
        events(null, null, PaidRequestChanged, PaymentCreated);
    }
    const createPay = async (order, e) => {
        if (!order.hasOwnProperty('paymentId')) {
            e.target.classList.add('loaded')
            let res = await Get.put({}, 'api/paid-requests/' + order.paidRequestId + '/start-payment', false);
            Store.services.time = 0;
            getOrders();
        } else {
            payOpen({ paymentId: order.paymentId })
        }

    }
    const payOpen = (data) => {
        var elems = document.querySelectorAll(".btn");

        [].forEach.call(elems, function (el) {
            el.classList.remove("loaded");
        });
        modalP.current.modaOpen(data);
    }
    const getCompleteStatus = async () => {
        let res = await Get.get('api/statuses?type=Paid');
        if (res.hasOwnProperty('data') && Array.isArray(res.data)) {
            let completeStatus = res.data.filter(item => item.name === 'Выполнено')[0];
            return completeStatus.statusId;
        }
        return false;
    }
    const OrderComplete = async (order) => {
        let statusID = await getCompleteStatus();
        if (statusID) {
            console.log(statusID);
            let data = await Get.patch({
                description: order.description,
                phoneNumber: order.phoneNumber,
                contactPerson: order.contactPerson,
                appealCategoryId: order.appealCategoryId,
                appealTypeId: order.appealTypeId,
                personalAccountId: order.personalAccountId,
                statusId: statusID,
            }, 'api/paid-requests/' + order.requestId);
            if (data.type == 'success') {
                Store.orders.time = 0;
                getOrders();
            }
        }
    }
    const counterPrice = (organizationServices) => {
        let ptice = 0
        if (Array.isArray(organizationServices)) {
            organizationServices.forEach(item => {
                ptice += item.price
            })
        }
        return ptice
    }

    useEffect(() => {
        ConnectSocket();
    }, [])
    useEffect(() => {
        getOrders();
    }, [])
    return (
        <>
            <div className='orders-page menu-container'>
                <Header data={{ title: ' Услуги' }} />

                <div className='tab-links-wrap pt-20'>
                    <div className='container'>
                        <ul className='tab-links flex-betwen'>
                            <li>
                                <Link to='/orders'>Заявки</Link>
                            </li>
                            <li>
                                <Link className="active" to='/services'>Услуги</Link>
                            </li>
                        </ul>
                    </div>
                </div>
                <div className='container pt-20'>
                    <div className='btns-container-status flex-start'>
                        <button type='button' className={`btn ${status == 'active' ? 'btn-orange' : 'btn-shadof'}`} onClick={() => setStatus('active')}>Активные</button>
                        <button type='button' className={`btn ${status == 'complete' ? 'btn-orange' : 'btn-shadof'}`} onClick={() => setStatus('complete')}>Выполненные</button>
                    </div>
                </div>
                <div className='container pt-20'></div>
                <div className='order-container container'>

                    {orders.length > 0 ? orders.map((el, index) => {

                        if (status == 'active' && (el.status.name != 'Выполнено' && el.status.name != "Выполнена исполнителем")) {
                            return (
                                <div className='order-item' key={index}>
                                    <Link to={`/service?id=${el.paidRequestId}`} key={index} className='order-item-link'>
                                        <div className='flex-start flex-wrap'>
                                            <div className='title'>Услуга № {el.paidRequestNumber}</div>

                                            <div className='date'>{config.dateFormatTimestapTime(el.createdOn)}</div>
                                        </div>
                                        <div className='pt-10'></div>
                                        <div className='status-block'>
                                            <div className='status-container' style={{ background: el.status.color }}>
                                                <div className='status flex-start'>
                                                    <img src={el.status.icon} />
                                                    <div className='status-title'>{el.status.name}</div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className='pt-15'></div>
                                        <div className='hr'></div>
                                        <div className='title-bilding'>
                                            {el.appealCategory.name}
                                        </div>
                                        <div className='pt-15'></div>
                                        <div className='message'>
                                            {el.description}
                                        </div>
                                    </Link>
                                </div>
                            );
                        }
                        if (status == 'complete' && (el.status.name == 'Выполнено' || el.status.name == "Выполнена исполнителем")) {
                            return (
                                <div className='order-item' key={index}>
                                    <Link to={`/service?id=${el.paidRequestId}`} key={index} className='order-item-link'>
                                        <div className='flex-start flex-wrap'>
                                            <div className='title'>Услуга № {el.paidRequestNumber}</div>

                                            <div className='date'>{config.dateFormatTimestapTime(el.createdOn)}</div>
                                        </div>
                                        <div className='pt-10'></div>
                                        <div className='status-block'>
                                            <div className='status-container' style={{ background: el.status.color }}>
                                                <div className='status flex-start'>
                                                    <img src={el.status.icon} />
                                                    <div className='status-title'>{el.status.name}</div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className='pt-15'></div>
                                        <div className='hr'></div>
                                        <div className='title-bilding'>
                                            {el.appealCategory.name}
                                        </div>
                                        <div className='pt-15'></div>
                                        <div className='message'>
                                            {el.description}
                                        </div>
                                    </Link>
                                </div>
                            );
                        }



                    }) : <div className='no-orders-container container'><div className="text-alert">Здесь будут отображаться ваши заявки и услуги. Вы можете создать их здесь</div></div>}
                </div>

            </div>
        </>
    )
}
export default Services;
