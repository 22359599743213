import React, { useState, useEffect } from 'react';
import {useLocation, useNavigate} from "react-router-dom";
import profileIcon from '../Images/Icons/profile.svg'

export const Header = (props) => {
    const location = useLocation();
    const data = props.data;
    const nazad = props.nazad == undefined ? false : true;
    const title = data.title !== undefined ? data.title : 'Заголовок не указан';
    let navigate = useNavigate();
    const historyBack = () => {
        navigate(-1);
    }

    return (

        <div className='fixed-header'>
            <div className={location.pathname !== '/profil' ? "flex-start flex-betwen": "flex-start flex-wrap"}>

                {nazad !== false ? <div className='link-nazad' onClick={() => historyBack()}></div> : ''}
                <div className='title'>{title}</div>

                {
                    location.pathname !== "/profil" ?
                        <div style={{
                            background: "lightgray",
                            borderRadius: "20px",
                            width: "32px",
                            height: "32px",
                            display: "flex",
                            justifyContent: "center",
                        }}
                             onClick={() => navigate('/profil')}
                        >
                            <img src={profileIcon} width='22'/>
                        </div>
                        : null

                }

            </div>
        </div>
    )
}
export default Header;
