import React, { useState, useEffect } from 'react';
import { jwtDecode } from "jwt-decode";
import Header from '../Elements/Header';
import { Link } from 'react-router-dom';
import Store from '../Store/Store';
import Config from '../Config/Config';

export const Domofon = () => {
    let config = new Config();
    let token = 'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ0b2tlbl91dWlkIjoiNjczZGE4OTYtMDhjNy00YjkyLWFhNWEtYWM5NWZmMzBkOTQ3IiwiZW50aXR5X3V1aWQiOiI3NTEzODMzZi0yZjVmLTQxYjktYWE1ZS1hMGY2NWRjZmE5NzMiLCJlbnRpdHlfdHlwZSI6InVzZXIiLCJlbnRpdHlfcmVmX3V1aWQiOiJiZTM3Y2UxOC1jMGExLTRkYWMtYTVkNC1hYTZlODJiNGQ2NDUiLCJlbnRpdHlfbmFtZSI6ImFwaSIsInNhbHQiOiJiNDVkMTQ5NyJ9.TzOvoycJ5yMrwTC5d3c7z-S0oQp2w4tgBaN7g049MSA';
    let url = 'https://api.sputnik.systems/query';
    let sdpUrl = 'https://streams.sputnik.systems/{uuid}/whap';
    let data = {
        query: 'mutation OpenDoor($input: OpenDoorInput!) {openDoor(input: $input) {success notice emergencyCode}}',
        variables: {
            input: {
                intercomID: '34a7112a-8334-4735-96b3-eee8ff4c4b3e',
                duration: null
            }
        }
    }
    const VideoData = `
        v=0
        o=- 2780315180736560480 2 IN IP4 127.0.0.1
        s=-
        t=0 0
        a=group:BUNDLE 0
        a=ice-lite
        m=video 9 UDP/TLS/RTP/SAVPF 102
        c=IN IP4 0.0.0.0
        a=rtcp:9 IN IP4 0.0.0.0
        a=candidate:1 1 udp 2113929983 195.211.43.38 40689 typ host
        a=candidate:1 1 tcp 2113929727 195.211.43.38 36951 typ host tcptype passive
        a=ice-ufrag:/bXc
        a=ice-pwd:kYrhWJZWJCHUNb4kVUl2jX
        a=ice-options:trickle
        a=fingerprint:sha-256 63:2E:1C:3E:F2:4F:6C:3D:E6:66:12:DE:27:F1:41:C6:45:47:DC:DE:BC:1C:BA:1D:14:82:A1:EC:4D:CB:FE:DF
        a=setup:active
        a=mid:0
        a=sendonly
        a=rtcp-mux
        a=rtpmap:102 H264/90000
        a=rtcp-fb:102 ccm fir
        a=rtcp-fb:102 nack
        a=rtcp-fb:102 nack pli
        a=rtcp-fb:102 goog-remb
        a=rtcp-fb:102 transport-cc
        a=fmtp:102 level-asymmetry-allowed=1;packetization-mode=1;profile-level-id=42001f
        a=ssrc:1405520306 cname:g2gHdwp3ZWJydGNf`;
    const [cameraID, setCameraId] = useState(null);
    const getDomofon = async () => {
        let res = null;
        let data = {
            query: 'query Query {intercoms {nodes {camera {uuid} uuid motherboardID}}}'
        }
        let response = await fetch(url, {
            method: "POST",
            headers: {
                "Content-Type": "application/json;charset=utf-8",
                "Authorization": token,
            },
            body: JSON.stringify(data),
        });
        res = await response.json().catch(() => {

        });

        if (res.hasOwnProperty('data') && res.data.hasOwnProperty('intercoms') && res.data.intercoms.hasOwnProperty('nodes')) {
            setCameraId(res.data.intercoms.nodes[0].camera.uuid);
        }
    }
    const [isDoorOpen, setIsDoorOpen] = useState(false);

    const openDoor = async () => {
        let response = await fetch(url, {
            method: "POST",
            headers: {
                "Content-Type": "application/json;charset=utf-8",
                "Authorization": token,
            },
            body: JSON.stringify(data),
        });

        if (response.ok) {
            setIsDoorOpen(true);

            setTimeout(() => setIsDoorOpen(false), 3000);
        }

    }

    const videoOpen = async () => {
        let localStream = null;
        // try {
        //     localStream = await navigator.mediaDevices.getDisplayMedia({ video: true, audio: true })
        // }
        // catch (e) {
        //     console.log(e);
        // }

        let localConnection = new RTCPeerConnection();
        // localConnection.addStream(localStream);
        let sdpObject = await localConnection.createOffer();

        // if (sdpObject.hasOwnProperty('sdp')) {
        //     let response = await fetch(sdpUrl.replace('{uuid}', cameraID), {
        //         method: "POST",
        //         mode: 'no-cors',
        //         headers: {
        //             "Content-Type": "text/plain;charset=utf-8",
        //             "Authorization": token,
        //         },
        //         body: sdpObject.sdp
        //     });
        //
        //     console.log(response)
        //
        //     let text = await response.text();
        //
        //     let streem = await resulult.blob();
        //
        //     let otherVideo = document.getElementById('localVideo1');
        //
        //     otherVideo.src = streem
        //     otherVideo.play()

        // }
        const gotStream = (stream) => {
            let video = document.querySelector('video');
            console.log('getUserMedia video stream URL:', stream);
            window.stream = stream; // stream available to console
            video.srcObject = stream;

        }
    }

    useEffect(() => {
        getDomofon();
    }, [])

    return (
        <div className='home-page menu-container'>
            <Header data={{ title: `Домофон` }} nazad={true} />
            <div className='container pt-40'>
                <video id="localVideo1" autoPlay={true}></video>
            </div>
            <div className='container pt-40'>
                <button type='button' className='btn btn-orange' onClick={() => { videoOpen() }}>Видео</button>
            </div>
            <div className='container pt-40'>
                {
                    isDoorOpen
                        ? <button type='button' className='btn btn-green' disabled>Дверь открыта</button>
                        : <button type='button' className='btn btn-orange' onClick={() => { openDoor() }}>Открыть дверь</button>
                 }
            </div>


        </div>
    )
}
export default Domofon;
