import React, { useState, useEffect, forwardRef, useImperativeHandle } from 'react';

export const ModalInfo = (props, ref) => {
    const [open, setOpen] = useState('');
    useImperativeHandle(ref, () => ({ modaOpen }));
    const [title, setTitle] = useState('');
    const [content, setContent] = useState('');
    const [func, setFunc] = useState(null)
    const modaOpen = (data) => {
        setOpen('open');
        if (data.hasOwnProperty('title')) {

            setTitle(data.title);

        }
        if (data.hasOwnProperty('content')) {

            setContent(data.content);
        }
        if (data.hasOwnProperty('func')) {

            setFunc(data);
        }

    }
    const propsFunc = () => {
        func.func()
    }

    const modaClose = () => {
        setOpen('');
    }
    return (
        <div className={`overflow ${open}`}>
            <div className='modal-container'>
                <div className='flex-betwen flex-wrap header-modal'>

                    <div className='title grow'>{title}</div>
                    <div className='close-container'>
                        <div className='close-modal' onClick={() => { modaClose() }}>&times;</div>
                    </div>
                </div>
                <div className='body-modal pt-20'>
                    <div className='pt-20'></div>
                    <div className='modal-desc'>
                        {content}
                    </div>
                    <div className='pt-20'></div>
                    <div className='flex-betwen flex-button'>
                        <button className='btn btn-green' onClick={() => { modaClose() }}>Нет</button>
                        <button className='btn btn-red' onClick={() => { propsFunc() }}>Да</button>
                    </div>
                </div>
            </div>
        </div>
    )
}
export default forwardRef(ModalInfo);