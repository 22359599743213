import React, { useState, useEffect } from 'react';
import Header from '../Elements/Header';
import { Link } from 'react-router-dom';
import InputPass from '../Elements/InputPass';
import InputMask from 'react-input-mask';
import Validate from '../Config/Validate';
import Get from '../Config/Get';
export const Changepass = () => {
    const [form, setForm] = useState(
        {
            value: {
                phone: '',

            },

            error: {
                phone: '',

            },
            focus: {
                phone: '',

            }
        }
    );
    const [loader, setLoader] = useState(0)
    const setValue = (name, value) => {

        setForm({
            ...form,
            value: {
                ...form.value,
                [name]: value
            }, focus: {
                ...form.focus,
                [name]: value !== '' ? 'focus' : ''
            }, error: {
                ...form.error,
                [name]: ''
            }
        })
    }
    const sendForm = async (e) => {
        e.preventDefault();
        if (Validate.Empty(form.value.phone)) {
            setForm({
                ...form,
                error: {
                    ...form.error,
                    phone: 'Поле обязательно для заполнения'
                },
                focus: {
                    ...form.focus,
                    phone: 'focus error'
                }
            });
            return;
        }
        if (Validate.Count(form.value.phone, 16, 16)) {
            setForm({
                ...form,
                error: {
                    ...form.error,
                    phone: 'Не корректный номер'
                },
                focus: {
                    ...form.focus,
                    phone: 'focus error'
                }
            });
            return;
        }
        setForm({
            ...form,

            focus: {
                phone: 'loaded'
            }
        }
        );
        let res = await Get.put({
            phone: Validate.Phone(form.value.phone)
        }, 'api/users/reset-password', false);
        if (res.hasOwnProperty('status') && res.status == 404) {
            setForm({
                ...form,

                focus: {
                    phone: 'focus error'
                },
                error: {
                    phone: 'Номер телефона не найден'
                }
            }
            );
        }
        if (res.hasOwnProperty('status') && res.status == 204) {
            setLoader(1)

        }
    }
    return (
        <div className='login-page'>
            <Header data={{ title: 'Новый пароль' }} nazad={true} />
            <div className='pt-40'></div>
            <div className='container'>
                <div className='changepass-form'>
                    {loader == 0 ?
                        <form onSubmit={(e) => { sendForm(e) }}>
                            <div className="grey-text">Введите телефон, который Вы указали при регистрации. На Вашу электронную почту, указанную при регистрации, будет выслан новый пароль.</div>
                            <div className='pt-40'></div>

                            <div className={`input-block ${form.focus.phone}`}>
                                <InputMask id="Phone" type="text" maskChar={null} mask="+7(999)999-99-99" value={form.value.phone} onChange={(e) => { setValue('phone', e.target.value) }} />
                                <label htmlFor="Phone">Телефон</label>
                                <span className='error'>{form.error.phone}</span>
                            </div>

                            <div className='input-block'>
                                <button type='submit' className='btn btn-orange'>Отправить</button>
                            </div>

                        </form>
                        : <div className='success-block'>
                            Проверьте почту, на почту указанную при регистрации, выслан новый пароль
                        </div>}
                </div>
                <div className='pt-15'></div>
                <div className='input-block'>
                    <Link to='/login' className='btn btn-white'>Войти</Link>
                </div>
            </div>
        </div>
    )
}
export default Changepass;