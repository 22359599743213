import React, { useState, useEffect, useRef } from 'react';
import Header from '../Elements/Header';
import { Link } from 'react-router-dom';
import InputPass from '../Elements/InputPass';
import InputMask from 'react-input-mask';
import Validate from '../Config/Validate';
import Get from '../Config/Get';
import { v4 as uuidv4 } from 'uuid';
import Alert from '../Elements/Alert';
export const Reg = () => {
    const modal = useRef(null);
    const [form, setForm] = useState(
        {
            value: {
                name: '',
                phone: '',
                email: '',
                password: '',
                repassword: ''
            },

            error: {
                name: '',
                phone: '',
                email: '',
                password: '',
                repassword: ''
            },
            focus: {
                name: '',
                phone: '',
                email: '',
                password: '',
                repassword: ''
            }
        }
    );
    const setValue = (name, value) => {

        setForm({
            ...form,
            value: {
                ...form.value,
                [name]: value
            }, focus: {
                ...form.focus,
                [name]: value !== '' ? 'focus' : ''
            }, error: {
                ...form.error,
                [name]: ''
            }
        })
    }
    const sendForm = async (e) => {
        e.preventDefault();
        if (Validate.Empty(form.value.name)) {
            setForm({
                ...form,
                error: {
                    ...form.error,
                    name: 'Поле обязательно для заполнения'
                },
                focus: {
                    ...form.focus,
                    name: 'focus error'
                }
            });
            return;
        }

        if (Validate.Cirilic(form.value.name)) {
            setForm({
                ...form,
                error: {
                    ...form.error,
                    name: 'Поле должно содержать только кириллицу'
                },
                focus: {
                    ...form.focus,
                    name: 'focus error'
                }
            });
            return;
        }
        if (Validate.Count(form.value.name, 3, 15)) {
            setForm({
                ...form,
                error: {
                    ...form.error,
                    name: 'Поле должно содержать не менее 3 и не более 15 символов'
                },
                focus: {
                    ...form.focus,
                    name: 'focus error'
                }
            });
            return;
        }
        if (Validate.Empty(form.value.phone)) {
            setForm({
                ...form,
                error: {
                    ...form.error,
                    phone: 'Поле обязательно для заполнения'
                },
                focus: {
                    ...form.focus,
                    phone: 'focus error'
                }
            });
            return;
        }
        if (Validate.Count(form.value.phone, 16, 16)) {
            setForm({
                ...form,
                error: {
                    ...form.error,
                    phone: 'Не корректный номер'
                },
                focus: {
                    ...form.focus,
                    phone: 'focus error'
                }
            });
            return;
        }
        if (Validate.Empty(form.value.email)) {
            setForm({
                ...form,
                error: {
                    ...form.error,
                    email: 'Поле обязательно для заполнения'
                },
                focus: {
                    ...form.focus,
                    email: 'focus error'
                }
            });
            return;
        }
        if (Validate.Email(form.value.email)) {
            setForm({
                ...form,
                error: {
                    ...form.error,
                    email: 'Не корректный e-mail'
                },
                focus: {
                    ...form.focus,
                    email: 'focus error'
                }
            });
            return;
        }
        if (Validate.Empty(form.value.password)) {
            setForm({
                ...form,
                error: {
                    ...form.error,
                    password: 'Поле обязательно для заполнения'
                },
                focus: {
                    ...form.focus,
                    password: 'focus error'
                }
            });
            return;
        }
        if (Validate.Empty(form.value.repassword)) {
            setForm({
                ...form,
                error: {
                    ...form.error,
                    repassword: 'Поле обязательно для заполнения'
                },
                focus: {
                    ...form.focus,
                    repassword: 'focus error'
                }
            });
            return;
        }
        if (Validate.Count(form.value.password, 5, 12)) {
            setForm({
                ...form,
                error: {
                    ...form.error,
                    password: 'Поле должно содержать не менее 6 и не более 12 символов'
                },
                focus: {
                    ...form.focus,
                    password: 'focus error'
                }
            });
            return;
        }
        if (form.value.password !== form.value.repassword) {
            setForm({
                ...form,
                error: {
                    ...form.error,
                    repassword: 'Пароли не совпадают'
                },
                focus: {
                    ...form.focus,
                    repassword: 'focus error'
                }
            });
            return
        }
        let uuid = uuidv4();
        let data = await Get.post({
            userId: uuid,
            name: form.value.name,
            userType: 'CitizenMobile',
            phoneNumber: Validate.Phone(form.value.phone),
            email: form.value.email,
            password: form.value.password,
        }, 'api/identity/token/signup');
        if (data.detail !== undefined && data.detail == 'User with same email is already exist') {
            setForm({
                ...form,
                error: {
                    ...form.error,
                    email: 'Пользователь с таким e-mail уже зарегистрирован'
                },
                focus: {
                    ...form.focus,
                    email: 'focus error'
                }
            });
            return;
        }
        if (data.detail !== undefined && data.detail == 'User with same phone number is already exist') {
            setForm({
                ...form,
                error: {
                    ...form.error,
                    phone: 'Пользователь с таким телефоном уже зарегистрирован'
                },
                focus: {
                    ...form.focus,
                    phone: 'focus error'
                }
            });
            return;
        }
        //User with same phone number is already exist
        if (data.status !== undefined && data.status !== 200) {
            if (modal.current !== null) {
                modal.current.modaOpen();
            }
            return
        }
        // console.log(data);
        localStorage.setItem('token', JSON.stringify(data));
        localStorage.setItem('UserId', uuid);
        window.location.href = '/';
    }

    return (
        <>
            <div className='login-page'>
                <Header data={{ title: 'Регистрация' }} />
                <div className='pt-40'></div>
                <div className='container'>
                    <div className='login-form'>
                        <form onSubmit={(e) => { sendForm(e) }}>
                            <div className={`input-block ${form.focus.name}`}>
                                <input type="text" id="Name" value={form.value.name} onChange={(e) => { setValue('name', e.target.value) }} />
                                <label htmlFor="Name">Как к Вам обращаться?</label>
                                <span className='error'>{form.error.name}</span>
                            </div>
                            <div className={`input-block ${form.focus.phone}`}>
                                <InputMask id="Phone" type="text" maskChar={null} mask="+7(999)999-99-99" value={form.value.phone} onChange={(e) => { setValue('phone', e.target.value) }} />
                                <label htmlFor="Phone">Телефон</label>
                                <span className='error'>{form.error.phone}</span>
                            </div>
                            <div className={`input-block ${form.focus.email}`}>
                                <input type="text" id="Email" value={form.value.email} onChange={(e) => { setValue('email', e.target.value) }} />
                                <label htmlFor="Email">E-mail</label>
                                <span className='error'>{form.error.email}</span>
                            </div>
                            <InputPass value={form.value.password} label="Пароль" id="Password" onChange={(e) => { setValue('password', e.target.value) }} focus={form.focus.password} error={form.error.password} />
                            <InputPass value={form.value.repassword} label="Повторите пароль" id="Repassword" onChange={(e) => { setValue('repassword', e.target.value) }} focus={form.focus.repassword} error={form.error.repassword} />
                            <div className='input-block'>
                                <button type='submit' className='btn btn-orange'>Зарегистироваться</button>
                            </div>

                        </form>
                    </div>
                    <div className='pt-15'></div>
                    <div className='input-block'>
                        <Link to='/login' className='btn btn-white'>Войти</Link>
                    </div>
                </div>
            </div>
            <Alert message="Возникла ошибка соединения, попробуйте позже" ref={modal} />
        </>
    )
}
export default Reg;