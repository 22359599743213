import React, { useState, useEffect } from 'react';
import ReactDOM from 'react-dom/client';
import { Routes, Route } from 'react-router-dom';
import AnonimRoute from './Login/AnonimRoute';
import PrivateRoute from './Login/PrivateRoute';
import Login from './Login/Login';
import Reg from './Login/Reg';
import Home from './Pages/Home';
import Changepass from './Login/Changepass';
import News from './Pages/News';
import ChatIndex from './Pages/ChatIndex';
import Chat from './Pages/Chat';
import Orders from './Pages/Orders';
import Profil from './Pages/Profil';
import ChangepassProfil from './Pages/Changepass';
import EditProfil from './Pages/EditProfil';
import Order from './Pages/Order';
import Pay from './Pages/Pay';
import Market from './Pages/Market';
import Services from './Pages/Services';
import Domofon from './Pages/Domofon';
import All from './Pages/All';
import OrderEdit from './Pages/Orderedit';
import Service from './Pages/Service';
import ServiceEdit from './Pages/Servicedit';
const Router = () => {
  const routes = [
    { path: '/', Component: Orders },
    { path: '/indexchat', Component: ChatIndex },
    { path: '/chat', Component: Chat },
    { path: '/pay', Component: Pay },
    { path: '/market', Component: Market },
    { path: '/orders', Component: Orders },
    { path: '/order', Component: Order },
    // { path: '/orderedit', Component: OrderEdit },
    { path: '/profil', Component: Profil },
    { path: '/news', Component: News },
    { path: '/domofon', Component: Domofon },
    { path: '/services', Component: Services },
    { path: '/service', Component: Service },
    // { path: '/serviceedit', Component: ServiceEdit },
    { path: '/editprofil', Component: EditProfil },
    { path: '/*', Component: All }

  ];

  return (

    <div className={`Router all-container`}>
      <Routes>

        <Route element={< AnonimRoute />}>
          <Route path="/login" element={< Login />} />
        </Route>

        <Route element={< PrivateRoute />} >
          {routes.map((el, index) => (

            <Route key={el.path} path={el.path} element={<el.Component />} />
          ))
          }
        </Route>

      </Routes>
    </div>
  );

}
export default Router;
